import React from 'react'
import * as rdd from 'react-device-detect';
import LazadaLandingAdsMobile from 'assets/ads/lazada/landing/320x100.webp';
import LazadaLandingAdsTablet from 'assets/ads/lazada/landing/750x100.webp';
import LazadaLandingAdsDesktop from 'assets/ads/lazada/landing/1920x240.webp';

import LazadaProductListerineAds from 'assets/ads/lazada/product/listerine750x4.webp';

import LazadaProductGarnierMaskAds from 'assets/ads/lazada/product/garnier_mask.webp';

import PlaceholderImage from 'assets/logo_full.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "css/ads.css";
import { POST } from 'api/api';

const DirectAdsComponent = ({adsType}) => {

    
    const handleAnchorClick = async (event) => {

        try {
            //console.log(event.target.id);
            const lottoActionResponse = await POST('/lotto/action', {ctaType : event.target.id, ctaDetail : 'lead to ' + event.target.id});
            console.log("call success");
        } catch (error) {
            console.error('/lotto/action --> error: ', error)
        }
        
    };

    return (
                <a  
                    href={getLazadaLink(adsType)}
                    target="_blank"
                    rel="noreferrer dns-prefetch"
                    onClick={handleAnchorClick}
                >
                    {showLazadaImg(adsType)}
                </a>       
    );
        
};

function getLazadaLink(adsType){
        
    if (adsType === "lazada_bonus_deeplink"){
        return "https://c.lazada.co.th/t/c.bA74Ec?sub_aff_id=lazada_bonus_deeplink"
    }
    if (adsType === "lazada_product_listerine"){
        return "https://c.lazada.co.th/t/c.bA7Sgj?sub_aff_id=lazada_product_listerine750x4_29percent"
    }
    if (adsType === "lazada_product_garniermask"){
        return "https://c.lazada.co.th/t/c.bA79DR?sub_aff_id=lazada_product_garniermask_10percent"
    }
}
function showLazadaImg(adsType){  
    if (adsType === "lazada_bonus_deeplink"){
        if (rdd.isTablet) 
            return (<LazyLoadImage id="lazada_bonus_deeplink" alt="lazada_bonus_deeplink" title="Hotto Ads sponsor Lazada" src={LazadaLandingAdsTablet} className="lazada-landing-tablet" effect="blur"/>)
        if (rdd.isMobile) 
            return (<LazyLoadImage id="lazada_bonus_deeplink" alt="lazada_bonus_deeplink" title="Hotto Ads sponsor Lazada" src={LazadaLandingAdsMobile} className="lazada-landing-mobile" effect="blur"/>)
        else
            return (<LazyLoadImage id="lazada_bonus_deeplink" alt="lazada_bonus_deeplink" title="Hotto Ads sponsor Lazada" src={LazadaLandingAdsDesktop}  className="lazada-landing-desktop" effect="blur"/>)
    }
    if (adsType === "lazada_product_listerine"){
        if (rdd.isTablet) 
            return (<LazyLoadImage id="lazada_product_listerine750x4_29percent" title="Hotto Ads sponsor Lazada" alt="lazada_product_listerine" src={LazadaProductListerineAds} className="lazada-product1x1" effect="blur"/>)
        if (rdd.isMobile) 
            return (<LazyLoadImage id="lazada_product_listerine750x4_29percent" title="Hotto Ads sponsor Lazada" alt="lazada_product_listerine" src={LazadaProductListerineAds} className="lazada-product1x1" effect="blur"/>)
        else
            return (<LazyLoadImage id="lazada_product_listerine750x4_29percent" title="Hotto Ads sponsor Lazada" alt="lazada_product_listerine" src={LazadaProductListerineAds}  className="lazada-product1x1" effect="blur"/>)
    }
    if (adsType === "lazada_product_garniermask"){
        if (rdd.isTablet) 
            return (<LazyLoadImage id="lazada_product_garniermask_10percent" title="Hotto Ads sponsor Lazada" alt="lazada_product_garniermask" src={LazadaProductGarnierMaskAds} className="lazada-product1x1" effect="blur"/>)
        if (rdd.isMobile) 
            return (<LazyLoadImage id="lazada_product_garniermask_10percent" title="Hotto Ads sponsor Lazada" alt="lazada_product_garniermask" src={LazadaProductGarnierMaskAds} className="lazada-product1x1" effect="blur"/>)
        else
            return (<LazyLoadImage id="lazada_product_garniermask_10percent" title="Hotto Ads sponsor Lazada" alt="lazada_product_garniermask" src={LazadaProductGarnierMaskAds}  className="lazada-product1x1" effect="blur"/>)
    }
}

export default DirectAdsComponent 