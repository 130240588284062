import React from 'react'

const SocialShare = () => {

    return (
          <div>

            {/* 
            <div className="line-it-button" 
              data-lang="en" 
              data-type="share-a" 
              data-env="REAL" 
              data-url="https://liff.line.me/1657746881-OnVGa7Yv" 
              data-color="default" 
              data-size="small" 
              data-count="false" 
              data-ver="3" 
            >
            </div>
            */}

            {/*
            <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-show-count="false">
            </a>
            */}

            {/*<br/> */}
            <div className="fb-like"
            data-href="https://hotto.app" 
            data-layout="button_count" 
            data-action="like" 
            data-size="small"  
            data-share="true"
            >
            </div>


        </div>
   );
};

export default SocialShare 