import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import {highlight,getLottoThDate,setQueryHyperlink} from 'components/lotto/utility/Utility.js';


export default function LottoKeywordHistory({ results, slug }) {

  //console.log("slug = " + slug);
  //console.log("LottoKeywordHistory:results = " + JSON.stringify(results));
  
  
  return (
    <Table border="0">
      <Thead>
        <Tr bgcolor="#f2f2f2">
          <Th style={{ verticalAlign : 'top'}}><h3 style={{fontSize:"1rem"}}><b> งวด</b></h3></Th>
          <Th style={{ verticalAlign : 'top'}}><h3 style={{fontSize:"1rem"}}><b> รางวัลที่หนึ่ง</b></h3></Th>
          <Th style={{ verticalAlign : 'top'}}><h3 style={{fontSize:"1rem"}}><b> สองหลักท้าย</b></h3> </Th>
          <Th style={{ verticalAlign : 'top'}}><h3 style={{fontSize:"1rem"}}><b> สามหลักท้าย</b></h3> </Th>
          <Th style={{ verticalAlign : 'top'}}><h3 style={{fontSize:"1rem"}}><b> 2ตัวล่าง</b></h3> </Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          results?.map((r,i) => (
            
            <Tr bgcolor="#f2f2f2" key={i} >
              <Td style={{ verticalAlign : 'top'}}>{getLottoThDate(r.lottoDate)} </Td>
              <Td style={{ verticalAlign : 'top'}}> 
                    {r.finalPrize.substring(0,r.finalPrize.length - 2)}{setQueryHyperlink(r.finalPrize.substring(r.finalPrize.length - 2), slug)}
              </Td>
              <Td style={{ verticalAlign : 'top'}}> {setQueryHyperlink(r.up2Prize, slug.slice(-2))}  </Td>
              <Td style={{ verticalAlign : 'top'}}> {setQueryHyperlink(r.up3Prize, slug.slice(-3))}   </Td>
              <Td style={{ verticalAlign : 'top'}}> {setQueryHyperlink(r.down2Prize, slug.slice(-2))}  </Td>
            </Tr>
          ))
        }
      </Tbody>
    </Table>
  );
}