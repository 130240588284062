import LazadaAdsProductBennett from 'assets/ads/lazada/product/bennett.webp';
import LazadaAdsProductSofee from 'assets/ads/lazada/product/sofee.webp';
import LazadaAdsProductGarnierMask from 'assets/ads/lazada/product/garnier_mask.webp';
import LazadaAdsProductListerine from 'assets/ads/lazada/product/listerine750x4.webp';

import LazadaAdsProductDowny from 'assets/ads/lazada/product/downy.webp';
import LazadaAdsProductKuma from 'assets/ads/lazada/product/kuma.webp';
import LazadaAdsProductColgateSalt from 'assets/ads/lazada/product/colgate_salt.webp';
import LazadaAdsProductProtect400x4 from 'assets/ads/lazada/product/protect400x4.webp';
import LazadaAdsProductProtectPropoliz450x4 from 'assets/ads/lazada/product/protectpropoliz450x4.webp';


let adsProduct = [
        { 
                id : "lazada_product_bennett"
            ,   adsImageUrl : LazadaAdsProductBennett
            ,   adsCaption : "สบู่เบ็นเน็ท 12 บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bAiasg?sub_aff_id=lazada_product_bennett_10percent"
        }
        ,
        { 
                id : "lazada_product_sofee"
            ,   adsImageUrl : LazadaAdsProductSofee
            ,   adsCaption : "ลอริเอะ/โซฟี เริ่มที่ 10 บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bAiawQ?sub_aff_id=lazada_product_sofee_10percent"
        }
        ,
        { 
                id : "lazada_product_garniermask"
            ,   adsImageUrl : LazadaAdsProductGarnierMask
            ,   adsCaption : "มาสก์หน้าการ์นิเย่ 19 บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bA79DR?sub_aff_id=lazada_product_garniermask_10percent"
        }
        ,
        { 
                id : "lazada_product_listerine"
            ,   adsImageUrl : LazadaAdsProductListerine
            ,   adsCaption : "Listerine 750มล 4 ขวด ราคาพิเศษ"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bA7Sgj?sub_aff_id=lazada_product_listerine750x4_29percent"
        }
        ,
        { 
                id : "lazada_product_downy"
            ,   adsImageUrl : LazadaAdsProductDowny
            ,   adsCaption : "น้ำยาปรับผ้านุ่ม Downy 19.80 บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bAiwpT?sub_aff_id=lazada_product_downy"
        }
        ,
        { 
                id : "lazada_product_kuma"
            ,   adsImageUrl : LazadaAdsProductKuma
            ,   adsCaption : "กระดาษทิชชู่เปียก Kuma 22 บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bAiyKl?sub_aff_id=lazada_product_kuma"
        }
        ,
        { 
                id : "lazada_product_colgatesalt"
            ,   adsImageUrl : LazadaAdsProductColgateSalt
            ,   adsCaption : "คอลเกต Salt 150กรัม 3 หลอด 139 บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bAiurC?sub_aff_id=lazada_product_colgatesalt150x3"
        }
        ,
        { 
                id : "lazada_product_protect400x4"
            ,   adsImageUrl : LazadaAdsProductProtect400x4
            ,   adsCaption : "โพรเทค 400มล 4ขวด ราคาพิเศษ"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bAiFCR?sub_aff_id=lazada_product_protect400x4_33percent"
        }
        ,
        { 
                id : "lazada_product_protectpropoliz450x4"
            ,   adsImageUrl : LazadaAdsProductProtectPropoliz450x4
            ,   adsCaption : "โพรเทค โพรโพลิซ 450มล 4ขวด ราคาพิเศษ"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bAiEJY?sub_aff_id=lazada_product_protectpropoliz450x4"
        }
        ,
        { 
                id : "lazada_product_sulwhasoo_foam"
            ,   adsImageUrl : "https://th-live-01.slatic.net/p/7bae9d257da5cd53020ae5432ef65f3e.jpg"
            ,   adsCaption : "โฟมล้างหน้า โซลวาซู 200มล ราคาพิเศษ"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bARSQO?sub_aff_id=lazada_product_sulwhasoo_foam"
        }
        ,
        { 
                id : "lazada_product_lux105x8"
            ,   adsImageUrl : "https://th-live.slatic.net/p/d25fc8cd863416af5226243e74bdfff2.jpg"
            ,   adsCaption : "ลักส์ สบู่ก้อน 105กรัม x 8 ราคา 118 บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bARiby?sub_aff_id=lazada_product_lux105x8"
        }
        ,
        { 
                id : "lazada_product_headnshoulder480ml"
            ,   adsImageUrl : "https://th-live-01.slatic.net/p/99a042459cc793c7db552529265c21e0.jpg"
            ,   adsCaption : "แชมพู Head&shoulder แชมพู 480มล 115บาท"
            ,   affiliateUrl : "https://c.lazada.co.th/t/c.bARSQy?sub_aff_id=lazada_product_headnshoulder480ml"
        }
      ];


export function getAdsProduct() {
    return adsProduct;
}