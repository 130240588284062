import React from "react";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import {directHighlight, setQueryHyperlink} from 'components/lotto/utility/Utility.js';

function renderResultArrayAsTr(resultArray, slug, backgroundColor) {
    //console.log("text = " + text)
    //console.log("renderResultArrayAsTr:resultArray = " + JSON.stringify(resultArray))
    let text = resultArray.length + " อันดับเลข \"" + resultArray[0].typeDescription + "\""
    let text2 = " (ย้อนหลัง " + resultArray[0].totalTimes + " งวด)";
    let rowspans = resultArray.length;
    let emptyCell = "";
    
    return  ( 
              <div style={{"backgroundColor":backgroundColor}}>
                <br/>
                <h3 style={{fontSize:'1rem'}}>
                <b>{text}</b>
                <br/>
                <b>{text2}</b>
                </h3>
                {
                resultArray.map((r, i) => (
                  <div key={i}>
                    <h4 style={{fontSize:'1rem'}}>
                      เลข&nbsp;{(slug.slice(-2) === r.lotto) 
                            || (slug.length > 2 && slug.slice(-3) === r.lotto) 
                              ? directHighlight(setQueryHyperlink(r.lotto, null)) 
                              : i <=2 ? <font color="green"><b>{setQueryHyperlink(r.lotto, null)}</b></font> : setQueryHyperlink(r.lotto, null)
                            }
                            &nbsp;(ถูก&nbsp;{r.times}&nbsp;งวด&nbsp;/&nbsp;
                            {(slug.slice(-2) === r.lotto) 
                            || (slug.length > 2 && slug.slice(-3) === r.lotto) 
                              ? directHighlight(r.winRate) 
                              : r.winRate
                            } %)
                    </h4>
                  </div>
                ))
                }
                <br/>
              </div>
    )
        
}

export default function LottoTopList({ results, slug }) {

  //console.log("LottoResultItems:results = " + JSON.stringify(results))
  
  return (
        <div>
        {
          renderResultArrayAsTr(results.down2DigitsTopList, slug, "#ffffbf")
        }
        {
          renderResultArrayAsTr(results.up2DigitsTopList, slug, "#d9ef8b")
        }
        {  
          renderResultArrayAsTr(results.up3DigitsTopList, slug, "#D6EEEE")
        }
        </div>
  );
}