import React from "react";

import { useState } from "react";

import useCollapse from 'react-collapsed';
//import { faCaretDown, faCaretUp } from '@fortawesome/fontawesome-free-solid'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, Tbody, Tr, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LottoResultItems from "components/lotto/results/LottoResultItems";
import LottoKeywordHistory from "components/lotto/results/LottoKeywordHistory";
import LottoTopList from "components/lotto/results/LottoTopList";
import LottoDreamList from "components/lotto/results/LottoDreamList";

import DirectAdsComponent from "../../../components/lotto/default/DirectAdsComponent";


export function ResultCollapsible({id, topic, table, slug , isShowAtRender}) {
  
//console.log("ResultCollapsible:isShowAtRender = " + isShowAtRender);
      
  const [ isExpanded, setExpanded ] = useState(isShowAtRender);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  function handleOnClick() {
    // Do more stuff with the click event!
    // Or, set isExpanded conditionally 
    setExpanded(!isExpanded);

    if (!isExpanded){
      window.open("https://c.lazada.co.th/t/c.bA74Ec?sub_aff_id=lazada_bonus_deeplink", "_blank");
    }
  }

  
  if (
            (id == 1 && table?.data.length > 0 && slug.length > 1)
      ||    (id == 2 && table?.length > 0  && slug.length > 1)
      ||    (id == 3 && (table?.down2DigitsTopList.length > 0 || table?.up2DigitsTopList.length > 0 || table?.up3DigitsTopList.length > 0))
      ||    (id == 9 && table?.length > 0)
  ) {
      return (
      <div className="collapsible">
            <div className={isExpanded ? "header-expand" : "header-collapse"} {...getToggleProps({onClick: handleOnClick})}>
            
            <Table>
                  <Tbody border="0">
                        <Tr style={{border : '0px'}}>
                        <Th style={{textAlignLast : 'center'}}>
                              <h2>
                                    <div style={{fontSize : '1.25rem'}}>
                                    {topic}
                                    </div>
                              </h2>
                              <div style={{fontSize : '0.85rem'}}> 
                              {/*
                                    {isExpanded ? "ซ่อน " : "ดูข้อมูล "} {isExpanded ? <FontAwesomeIcon icon="caret-up" /> : <FontAwesomeIcon icon="caret-down" />}
                              */}
                              {isExpanded ? "ซ่อน " : "ดูข้อมูล "}
                              </div>

                        </Th>                              
                        </Tr>
                  </Tbody>
            </Table>

            </div>
            <div {...getCollapseProps()} >
                  <div className="content">
                  
                  {id === "1" && <div> <br/> เลขไหนเด็ดของจริง ถูกบ่อย ถูกถี่กว่าเลขอื่น เช็คเลย!!! <br/><br/></div>}
                  {id === "1" && <LottoResultItems results={table} /> }
                  
                  {id === "2" && <div> <br/> เลขเด็ดของคุณ เด็ดจริงไหม ถูกบ่อยแค่ไหน ไปดูกันเลย!!! <br/><br/></div>}
                  {id === "2" && <LottoKeywordHistory results={table}  slug={slug} /> }
                  
                  {id === "3" && <div> <br/> เลขไหนเด็ดของจริง ถูกบ่อย ถูกถี่กว่าเลขอื่น เช็คเลย!!! <br/><br/></div>}
                  {id === "3" && <LottoTopList results={table}  slug={slug} /> }

                  {id === "9" && <div> <br/>  เลขเด็ด เลขนำโชค เลขหลัก เลขรอง จากฝันนั้นคืออะไร ไปดูกันเลย!!! <br/><br/></div>}
                  {id === "9" && <LottoDreamList results={table} slug={slug} /> }
                  
                  </div>
                  <hr/>                  
                  <DirectAdsComponent adsType="lazada_bonus_deeplink"/>
                  
            </div>
            <hr />
      
      </div>
      );
  }
}
