import React, { Component } from 'react'
import { POST } from 'api/api';

export default class LineLogin extends Component {
    constructor(props) {
        super(props)
        this.params = props.params
    }


    getLineAuthenUrl = async () => {

        try {
            const res = await POST('/user/lineAuthen', {
                sessionId: '/home',
            })
            console.log("res.lineAuthenUrl = " + res.lineAuthenUrl);
            window.location.assign(res.lineAuthenUrl)
        } catch (error) {
            console.error('lineAuthen ~ error', error)
            window.location.replace('/home')
        }
    }

    componentDidMount() {
        let hasAuthorization = false
        //if (window.localStorage.getItem('_token_')) {
        //    window.location.replace('/home')
        //} 
    }



    render() {

        console.log("LineLogin");
        const { t } = this.props
        this.getLineAuthenUrl();
        return (
        <div>redirect to LINE LOGIN...</div>
        )
    }
}
