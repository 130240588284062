import React from "react";
import useSWR from "swr";
import Cosmic from "cosmicjs";

const api = Cosmic();

const bucket = api.bucket({
  slug: "293f2770-8a68-11ed-b039-3f473b7cfbb9",
  read_key: "OuUQ2vnL5dNA6QtpbLFDvdyPFZ3L4MZcTFkHdjIKAakYltWFlg",
});

const fetchPosts = async () => {
  const data = await bucket.objects
    .find({
      type: "posts",
    })
    .props('slug,title,thumbnail,metadata'); // Limit the API response data by props
  //console.log("fetchPosts:data = " + JSON.stringify(data));
  return data;
};

function ArticleList() {
  const { data, error } = useSWR("fetch-posts", fetchPosts); // Use SWR hook
  if (!data) return <div>Loading...</div>;
  const posts = data.objects;
  return (
    <div>
      <h2> บทความพิเศษ</h2>

      <div> อ่านบทความสถิติเลขเด็ด เพื่อเพิ่มโอกาสถูกรางวัลก่อนใคร คลิกเลย!!! <br/></div>

      {posts.map((post) => (
        <div key={post.slug} style={{ marginBottom: 20 }}>
        
          <div>
            <a 
              href={"https://hotto.app/article/" + post.slug}
              target="_blank"
            >
              <img alt={post.title} src={`${post.thumbnail}?w=300`} />
            </a>
          </div>
          <div style={{maxWidth:"300px", margin:"0 auto", fontSize : "0.75rem"}}>
              {post.title}<br/>
              <a 
              href={"https://hotto.app/article/" + post.slug}
              target="_blank"
              >
              คลิกเพื่ออ่านบทความ
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
export default ArticleList;