import 'App.css';
import React from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { POST } from 'api/api';
import Lotto from "components/lotto/Lotto.js";
import 'css/style.css';
import Logo from 'assets/logo_full.png';
import PlaceholderImage from 'assets/logo_full.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useParams } from "react-router-dom";
import {useEffect, useState} from 'react';

import {prepareTxtTitle, prepareTxtDescription, prepareTxtCanonicalUrl, prepareTxtKeywords} from 'components/lotto/utility/Utility.js';

import {Helmet} from "react-helmet";

export default function Main() {
  
  const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  

  const [configData, setConfigData] = useState(null);
  //console.log("Lotto:hintDataArray = " + JSON.stringify(hintDataArray));
  useEffect(() => {
    const getConfigData = async () => {
        try {
            const configData = await POST('/lotto/config', {urlParameter : keyword})
            //console.log("configData = " + JSON.stringify(configData))
            setConfigData(configData)
        } catch (error) {
            console.log('/lotto/config --> error: ', JSON.stringify(error))
        }
    }
    getConfigData()
  }, [])

  let mode = "default";

  let { keyword } = useParams();

  
  if (!isNaN(+keyword?.trim())){
    mode = "number";
  }
  else if (keyword){
    mode = "dream";
  }

  console.log("Main.js:keyword = " + keyword);
  console.log("Main.js:mode = " + mode);

  return (
    
    <div className="c" style={{maxWidth:windowSize.innerWidth}}> 
          <Helmet>
                <meta charSet="utf-8" />
                <title>{prepareTxtTitle(mode, keyword)}</title>
                <meta name="description" content={prepareTxtDescription(mode, keyword)} />
                <meta name="keywords" content={prepareTxtKeywords(mode, keyword)} />
                <link rel="canonical" href={prepareTxtCanonicalUrl(mode, keyword)} />

                <link rel="alternate" hreflang="th-th" href={prepareTxtCanonicalUrl(mode, keyword)} />
                <link rel="alternate" hreflang="x-default" href={prepareTxtCanonicalUrl(mode, keyword)} />
                
                <meta property="og:title" content="Hotto | ครบเครื่องเรื่องหวย เลขเด็ดงวดนี้ ทำนายฝัน" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={prepareTxtCanonicalUrl(mode, keyword)} />
                <meta property="og:description" content="หาเลขเด็ดจากฝัน เลขคำชะโนด ปฎิทินจีน เช็คเลขเด็ดย้อนหลัง 32 ปี" />
                <meta property="og:image" content="https://hotto.app/api/topten/lotto/image/hint?type=CALENDAR_KUMCHANOD&id=CALENDAR_KUMCHANOD" />
                
                <meta property="twitter:title" content="Hotto | ครบเครื่องเรื่องหวย เลขเด็ดงวดนี้ ทำนายฝัน" />
                <meta property="twitter:card" content="summary" />
                <meta property="twitter:site" content="@TanateSukonrat2" />
                <meta property="twitter:creator" content="@TanateSukonrat2" />
                <meta property="twitter:description" content="หาเลขเด็ดจากฝัน เลขคำชะโนด ปฎิทินจีน เช็คเลขเด็ดย้อนหลัง 32 ปี" />
                <meta property="twitter:image" content="https://hotto.app/api/topten/lotto/image/hint?type=CALENDAR_KUMCHANOD&id=CALENDAR_KUMCHANOD" />
                <meta property="twitter:image:alt" content="หาเลขเด็ดจากฝัน เลขคำชะโนด ปฎิทินจีน เช็คเลขเด็ดย้อนหลัง 32 ปี"/>

          </Helmet>


          <div className="c05in100">
              
          </div>
          
          <div className="c95in100">
            <a href="https://hotto.app">
            <LazyLoadImage 
              src={Logo}
              rel="dns-prefetch"
              alt="Hotto | ครบเครื่องเรื่องหวย เลขเด็ดงวดนี้ ทำนายฝัน" 
              title="Hotto | ครบเครื่องเรื่องหวย เลขเด็ดงวดนี้ ทำนายฝัน"
              placeholdersrc={PlaceholderImage}
              effect="blur"
              width={180} 
              height={180}
            />
            </a>
            <br />
          
            {configData && <Lotto isShowAtRender={showAtRender(configData)} keyword={keyword} />}
            </div>
            <div className="c10in100">
              
            </div>
    </div>
  );

}

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function showAtRender(configData){
  
  
  //console.log("App.js:configData = " + JSON.stringify(configData));

  if (configData){
    return configData?.data?.find(c => {
      return c.configKey === 'collapse.showAtRender';
    }).configValue === 'true' ? true : false;
    ;
  } 
  else{
    return false;
  } 
}

