import React from "react";
import { useState } from "react";

import useCollapse from 'react-collapsed';
//import { faCaretDown, faCaretUp } from '@fortawesome/fontawesome-free-solid'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, Tbody, Tr, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import LottoPrize  from "components/lotto/default/LottoPrize";

import DirectAdsComponent from "../../../components/lotto/default/DirectAdsComponent";

export function PrizeCollapsible({topic, prizeDataArray, slug , isShowAtRender}) {
      //const { getCollapseProps, getToggleProps, isCollapsed } = useCollapse();
    
      const [ isExpanded, setExpanded ] = useState(isShowAtRender);
      const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
    
      function handleOnClick() {
        // Do more stuff with the click event!
        // Or, set isExpanded conditionally 
        setExpanded(!isExpanded);

        if (!isExpanded){
          window.open("https://c.lazada.co.th/t/c.bA74Ec?sub_aff_id=lazada_bonus_deeplink", "_blank");
        }
      }
      //console.log("topic = " + topic);
      //console.log("prizeDataArray = " + JSON.stringify(prizeDataArray));

      return (
      <div className="collapsible">
            <div className={isExpanded ? "header-expand" : "header-collapse"} {...getToggleProps({onClick: handleOnClick})}>
            
                <Table>
                  <Tbody>
                  <Tr style={{border : '0px'}}>
                      <Th className="borderRounded">
                            <h2>
                              <div style={{fontSize : '1.25rem'}}>
                              {topic}
                              </div>
                            </h2>
                            <div style={{fontSize : '0.85rem'}}> 
                              {/*
                                    {isExpanded ? "ซ่อน " : "ดูข้อมูล "} {isExpanded ? <FontAwesomeIcon icon="caret-up" /> : <FontAwesomeIcon icon="caret-down" />}
                              */}
                              {isExpanded ? "ซ่อน " : "ดูข้อมูล "}
                            </div>

                      </Th>
                  </Tr>
                  </Tbody>
                </Table>
            </div>
            <div {...getCollapseProps()}>

              <div> <br/> เช็คหวยรางวัลที่หนึ่ง และ รางวัลสองตัวล่าง งวดปัจจุบัน และย้อนหลังได้ที่นี่จ้า!!! <br/></div>
                  
                  <div className="content"   style={{ height: '300px', overflowY: 'scroll'}}>
                    <LottoPrize prizeDataArray={prizeDataArray} slug={slug} />
                  </div>

                  <hr/>                  
                  <DirectAdsComponent adsType="lazada_bonus_deeplink"/>
                  
            </div>
            <hr />
    
      </div>
      );
}
