import React from 'react'
import { POST } from 'api/api';
import { useEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";

export default function LineOauthCallback( {} ) {

    const [searchParams, setSearchParams] = useSearchParams();


    const lineStateType = 'DEFAULT';
    
    
    const [loginData, setLoginData] = useState(null);
    useEffect(() => {

        const getLoginData = async () => {
            let lineStateType = 'DEFAULT'

            try {
                // alert("/user/signUp")
                const res = await POST('/user/signUp', {
                    userType: 'LINE',
                    lineCode: searchParams.get('code'),
                    lineState: searchParams.get('state'),
                    lineStateType
                })
                const data = {
                    loginType: 'LINE',
                    username: res.data.userName,
                    lineCode: searchParams.get('code'),
                    lineState: searchParams.get('state'),
                    lineStateType
                }

                try {
                    const res = await POST('/user/logIn', data)
    
                    window.localStorage.setItem('_token_', res.token)
                    window.location.replace('/home')
    
                    setLoginData(res);
                } catch (error) {
                    console.error('login error', error)
                    window.location.replace('/home')
                }
                

            } catch (error) {
                console.error('signup error', error)
                window.location.replace('/home')
            }
        }
    
      getLoginData()
    }, [])

    return (
        <div>Logging in...</div>
    )
    
}
