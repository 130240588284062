import React from 'react';
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {Carousel} from "react-bootstrap";
//import {Carousel} from "react-responsive-carousel";

import "css/carousel.css";
import PlaceholderImage from 'assets/logo_full.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
//import { POST } from "api/api.js";
import {hintTypeDescription, setQueryHyperlink} from 'components/lotto/utility/Utility.js';


const LottoHintCarousel = ({hintDataArray, slug}) => {

    //console.log("LottoHintCarousel:hintDataArray : " + JSON.stringify(hintDataArray));
    return (
        <div  style={{ width: "100%"}}> 
            
            <Carousel
                autoPlay={true}
                interval={5000}
                variant="dark"
                touch={true}
                indicators={false}
            >
                {hintDataArray?.map((a, i) => (
                    
                    <Carousel.Item key={i}>
                        
                        <h3 style={{width: '275px', height: '350px', margin: '0 auto'}}>
                            {hintTypeDescription(a.hintType)}
                            <LazyLoadImage 
                                id={a.hintType}
                                src={a.hintImgUrl}
                                rel="dns-prefetch"
                                style={{width: '275px', height: '220px', margin: '0 auto'}}
                                alt={hintTypeDescription(a.hintType)}
                                title={hintTypeDescription(a.hintType)}
                                placeholdersrc={PlaceholderImage}
                                effect="blur"
                            />
                            <div id={a.hintType} style={{ fontWeight: 'bold', fontSize: '0.85rem', color: 'black', width: '100%' }}>
                                {setQueryHyperlink(a.hintNumbers, slug)}
                            </div>
                        </h3>

                        
                    </Carousel.Item>
                ))
                }
            </Carousel>
            
        </div>
    );
};

export default LottoHintCarousel;