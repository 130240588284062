import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import {highlight,setQueryHyperlink} from 'components/lotto/utility/Utility.js';



export default function LottoDreamList({ results, slug }) {

  //console.log("slug = " + slug);

  results?.map((r, i) => (
    r.id = i + 1
  ))
  //console.log("LottoDreamList:results = " + JSON.stringify(results));
  
  return (
    results && 
    <Table>
      <Thead>
        <Tr bgcolor="#f2f2f2">
          <Th><h3 style={{fontSize:"1rem"}}><b> คำหลัก</b></h3></Th>
          <Th><h3 style={{fontSize:"1rem"}}><b> ความฝัน</b></h3></Th>
          <Th><h3 style={{fontSize:"1rem"}}><b>คำทำนาย</b></h3> </Th>
          <Th><h3 style={{fontSize:"1rem"}}><b>เลขนำโชค</b> </h3></Th>
          <Th><h3 style={{fontSize:"1rem"}}><b>เลขหลัก</b></h3></Th>
          <Th><h3 style={{fontSize:"1rem"}}><b>เลขรอง</b></h3></Th>
        </Tr>
      </Thead>
      <Tbody>
      {results?.map((r, i) => (
          <Tr bgcolor="#f2f2f2" key={i}>
            <Td style={{ verticalAlign : 'top'}}> {highlight(r.dreamKeyword, slug)} </Td>
            <Td style={{ verticalAlign : 'top'}}> {highlight(r.description, slug)} </Td>
            <Td style={{ verticalAlign : 'top'}}> {highlight(r.horo, slug)} </Td>
            <Td style={{ verticalAlign : 'top', textAlign: 'center'}}> 
                {slug.length < 2 ? setQueryHyperlink(r.luckyNumbers, null) : setQueryHyperlink(r.luckyNumbers, slug)}
            </Td>
            <Td style={{ verticalAlign : 'top'}}> 
                {slug.length > 1 ? setQueryHyperlink(r.main, null) : setQueryHyperlink(r.main, slug)}
            </Td>
            <Td style={{ verticalAlign : 'top'}}> 
                {slug.length > 1 ? setQueryHyperlink(r.second, null) : setQueryHyperlink(r.second, slug)}
            </Td>
          </Tr>
      ))}
      </Tbody>
    </Table>
  );
}