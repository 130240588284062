import { useEffect, useState} from "react";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import useFetch from "hooks/useFetch";

import { GET, POST } from 'api/api';
import LottoResults from "components/lotto/results/LottoResults";
//import GoogleAdsComponent from "components/lotto/default/GoogleAdsComponent";
import ContactUs from "components/lotto/default/ContactUs";
import OtherApps from "components/lotto/default/OtherApps";
//import DirectAdsCarousel from "components/lotto/default/DirectAdsCarousel";
import DirectAdsComponent from "components/lotto/default/DirectAdsComponent";
  
import SocialShare from "components/lotto/default/SocialShare";

import { InputGroup } from 'reactstrap';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "css/input-box.css";


//import LottoHint  from "components/lotto/main/LottoHint";

import {PrizeCollapsible} from 'components/lotto/utility/PrizeCollapsible';
import {HintCollapsible} from 'components/lotto/utility/HintCollapsible';

import {ResultCollapsible} from 'components/lotto/utility/ResultCollapsible';

import { format } from 'date-fns'

import ArticleList from 'components/lotto/ArticleList';

export default function Lotto( {isShowAtRender, keyword} ) {
  console.log("Lotto1:keyword = " + keyword);
  //console.log("Lotto:isShowAtRender = " + isShowAtRender);
  localStorage.setItem('isShowAtRender', isShowAtRender);
  //localStorage.setItem('keyword', keyword);

  
  //console.log("Lotto:data = " + JSON.stringify(data))
  //console.log("process.env.REACT_APP_BASE_URL = " + process.env.REACT_APP_BASE_URL);
  
  const { data, setData } = useFetch();

  const chooseSlug = ({slug}) => {
    if (keyword) {
      console.log("Lotto2:keyword = " + keyword);
      if (data.slug !== keyword){
        setData({ ...data, slug: keyword });
        console.log("Lotto1:data = " + data);
      }
      return keyword;
    }
    else {
      console.log("Lotto3:slug = " + slug);
      return slug;
    }
  }

  const [hintAndPrizeData, setHintAndPrizeData] = useState(null);
  useEffect(() => {
    const getHintAndPrizeData = async () => {
        try {
            const hintAndPrizeData = await GET('/lotto/hint?q=', {})
            //console.log("hintAndPrizeData = " + JSON.stringify(hintAndPrizeData))

            setHintAndPrizeData(hintAndPrizeData)
            
        } catch (error) {
            console.error('/lotto/hint --> error: ', error)
        }
    }
    getHintAndPrizeData()
  }, [])

  const [topListDataResponse, setTopListDataResponse] = useState(null);
  useEffect(() => {
    const getTopListDataResponse = async () => {
        try {
            const topListDataResponse = await POST('/lotto/get-top-list', {})
            setTopListDataResponse(topListDataResponse)
        } catch (error) {
            console.error('/lotto/get-top-list --> error: ', error)
        }
    }
    getTopListDataResponse()
  }, [])

  const [topList1YDataResponse, setTopList1YDataResponse] = useState(null);
  useEffect(() => {
    const getTopList1YDataResponse = async () => {
        try {
            var beg = new Date();
            beg.setFullYear(beg.getFullYear() - 1);
            beg = format(beg,'yyyy-MM-dd');
            var end = new Date();
            end = format(end,'yyyy-MM-dd');
            const topList1YDataResponse = await POST('/lotto/get-top-list', {begDate: beg, endDate: end})
            setTopList1YDataResponse(topList1YDataResponse)
        } catch (error) {
            console.error('/lotto/get-top-list --> error: ', error)
        }
    }
    getTopList1YDataResponse()
  }, [])

  const [topList3YDataResponse, setTopList3YDataResponse] = useState(null);
  useEffect(() => {
    const getTopList3YDataResponse = async () => {
        try {
            var beg = new Date();
            beg.setFullYear(beg.getFullYear() - 3);
            beg = format(beg,'yyyy-MM-dd');
            var end = new Date();
            end = format(end,'yyyy-MM-dd');
            const topList3YDataResponse = await POST('/lotto/get-top-list', {begDate: beg, endDate: end})
            setTopList3YDataResponse(topList3YDataResponse)
        } catch (error) {
            console.error('/lotto/get-top-list --> error: ', error)
        }
    }
    getTopList3YDataResponse()
  }, [])

  const onEnter = (e) => {
    if (e.key === "Enter") {
      console.log("ENTER")
      e.target.blur();
    }
  };

  return (
    <>
    <main>
          { !keyword && (<h2 className="blinktext">พิมพ์ฝันหาเลขเด็ด </h2>)}

          { !keyword && 
          <div className="input-icon-addon">
            <InputGroup>      
              <input
                id = "input-box"
                type="text"
                placeholder="ฝันว่า ???"
                autoFocus
                value={ chooseSlug(data.slug) }
                onChange={(e) => setData({ ...data, slug: e.target.value })}
                onKeyUp={onEnter}
              />
            </InputGroup> 
            </div>            
          }
          
          <div hidden>{ keyword && chooseSlug(data.slug)}</div>
          
                                     {/*
            <FontAwesomeIcon 
              icon="search" 
              id="input-icon"
              onClick={console.log('Added to favorites!')}
            />
            */}


      <br />
        
      <SocialShare />

      <br />
      {data.results.statusCode === 200 
          ? <LottoResults data={data} isShowAtRender={isShowAtRender} /> 
          : data.results.statusCode === 404 
            ? (<div><font color="red" size="2rem"> กรุณาพิมพ์ความฝันของท่านให้สมบูรณ์ <br/><br/></font></div>) 
            : null
      }

      <HintCollapsible topic="เลขเด็ดงวดนี้" hintDataArray={hintAndPrizeData?.hintData} slug={data.slug} isShowAtRender={isShowAtRender}/>
    
      <PrizeCollapsible topic="ผลรางวัลที่หนึ่ง/สองตัวล่าง" prizeDataArray={hintAndPrizeData?.prizeData} slug={data.slug} isShowAtRender={isShowAtRender}/>
  
      <ArticleList />      

      <ResultCollapsible id="3" topic={"เลขถูกบ่อย สถิติย้อนหลัง 1 ปี"} table={topList1YDataResponse} slug={data.slug} isShowAtRender={false}/>
      
      <ResultCollapsible id="3" topic={"เลขถูกบ่อย สถิติย้อนหลัง 3 ปี"} table={topList3YDataResponse} slug={data.slug} isShowAtRender={false}/>
      
      <ResultCollapsible id="3" topic={"เลขถูกบ่อย สถิติย้อนหลัง " + (new Date().getFullYear() -  1990) + " ปี"} table={topListDataResponse} slug={data.slug} isShowAtRender={false}/>      
      
      <ResultCollapsible id="9" topic={"ความฝันยอดฮิต"} table={hintAndPrizeData?.dreamData} slug={data.slug} isShowAtRender={isShowAtRender}/>
      
      <OtherApps nextLottoTimestamp={hintAndPrizeData?.hintData[0].lottoDate} />

      {/*
      <DirectAdsCarousel/>
      */}
      <DirectAdsComponent adsType="lazada_bonus_deeplink"/>
      <hr/>
      
      <ContactUs/>
      
      {/*
      <GoogleAdsComponent/>
      */}

    </main>
    </>
  );

}
