import * as React from 'react';
import useSWR from "swr";
import Cosmic from "cosmicjs";
import { useSearchParams } from "react-router-dom";

import "css/blog.css";
import {getThDate, prepareTxtTitle, prepareTxtDescription, prepareTxtCanonicalUrl, prepareTxtKeywords} from 'components/lotto/utility/Utility.js';

import SocialShare from "components/lotto/default/SocialShare";

import DirectAdsCarousel from "components/lotto/default/DirectAdsCarousel";
import DirectAdsComponent from "components/lotto/default/DirectAdsComponent";
import ContactUs from "components/lotto/default/ContactUs";

import {Helmet} from "react-helmet";

const api = Cosmic();

const bucket = api.bucket({
  slug: "293f2770-8a68-11ed-b039-3f473b7cfbb9",
  read_key: "OuUQ2vnL5dNA6QtpbLFDvdyPFZ3L4MZcTFkHdjIKAakYltWFlg",
});


const ArticleDetail = () => {
  
  console.log("ArticleDetail.js");

  const [searchParams, setSearchParams] = useSearchParams();


  let slug = searchParams?.get('slug');

  //console.log("ArticleDetail.js:slug = " + slug);

  const fetchPosts = async () => {
    const data = await bucket.objects
      .find({
        type: "posts", slug: slug
      })
      .props('slug,title,thumbnail,content,metadata,published_at'); // Limit the API response data by props
    //console.log("fetchPosts:data?.objects[0].content = " + JSON.stringify(data?.objects[0].content));
    return data;
  };

  const { data, error } = useSWR("fetch-posts", fetchPosts); // Use SWR hook
  if (!data) return <div>Loading...</div>;
  //const posts = data.objects;
  const post = data.objects[0];
  const cosmic = data;
  cosmic.post = post;
  return (
          <>
            <Helmet>
              <title>{prepareTxtTitle("article", slug)}</title>
              <meta name="description" content={prepareTxtDescription("article", slug)} />
              <meta name="keywords" content={prepareTxtKeywords("article", slug)} />

              <link rel="canonical" href={prepareTxtCanonicalUrl("article", slug)} />
              <link rel="alternate" hreflang="th-th" href={prepareTxtCanonicalUrl("article", slug)} />
              <link rel="alternate" hreflang="x-default" href={prepareTxtCanonicalUrl("article", slug)} />
              <meta property="og:description" content={cosmic.post.title} />
              <meta property="og:image" content={`url(${cosmic.post.thumbnail}?w=2000&auto=format)`} />
                
            </Helmet>
            <div>
              <title key="sitetitle">{ cosmic.post.title }</title>
              {
                cosmic.post && cosmic.post.thumbnail &&
                <div className="blog-post-hero" style={{ backgroundImage: `url(${cosmic.post.thumbnail}?w=2000&auto=format)`}}></div>
              }
              <main className="container">
                <div className="card-padding">
                  <h2 className="blog__title">
                    {
                      !cosmic.post &&
                      <div style={{ textAlign: 'center' }}>Post Not found</div>
                    }
                    {
                      cosmic.post &&
                      <div><a href={`/article/${cosmic.post.slug}`}>{cosmic.post.title}</a></div>
                    }
                  </h2>
                  {
                    cosmic.post &&
                    <div>
                      <div className="blog__author">
                        <div className="blog__author-image" style={{ backgroundImage: `url(${cosmic.post.metadata.author.metadata.image.imgix_url}?w=100)`}}></div>
                        <div className="blog__author-title">โดย {cosmic.post.metadata.author.title} วันที่ {getThDate(cosmic.post.published_at)}</div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="c">
                        <SocialShare />
                      </div>

                      <div className="blog__teaser droid" dangerouslySetInnerHTML={{__html: cosmic.post.content}}></div>

                      <div className="c">
                        <SocialShare />
                      </div>

                    </div>
                  }
                </div>
                
              </main>
              <hr/>
              <div className="c95in100">
                <DirectAdsCarousel/>
                <DirectAdsComponent adsType="lazada_bonus_deeplink"/>
                <hr/>
                <ContactUs/>
              </div>

            </div>
          </>

        )
        

}
export default ArticleDetail;