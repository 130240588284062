import axios from "axios";

import { sha256 } from 'js-sha256';
import { format } from 'date-fns';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
  //baseURL: "http://localhost:8080/topten",
  //baseURL: "https://game-of-thrones-quotes.herokuapp.com/v1/house",
  baseURL: process.env.REACT_APP_BASE_URL
});


export const GET = (path) => {
  //console.log("GET : " + process.env.REACT_APP_API_KEY + "." + format(new Date(),'yyyy-MM-dd') + ".get");
  return new Promise((resolve, reject) => {
      axios({
          headers: {
              'Content-Type': 'application/json',
              'x-api-key' : sha256(process.env.REACT_APP_API_KEY + "." + format(new Date(),'yyyy-MM-dd') + ".get")
          },
          method: 'get',
          url: `${BASE_URL}${path}`,
          data: ''
      })
          .then((res) => res.data)
          .then((json) => (json.statusCode >= 301 ? reject(json) : resolve(json)))
          .catch((error) => {
              reject(error)
              console.log(error)
          })
  })
}

export const POST = (path, obj) => {
  //console.log(process.env.REACT_APP_API_KEY + "." + format(new Date(),'yyyy-MM-dd') + "." + (JSON.stringify(obj)));
  
  return new Promise((resolve, reject) => {
      axios({
          headers: {
              'Content-Type': 'application/json',
              'x-api-key' : sha256(process.env.REACT_APP_API_KEY + "." + format(new Date(),'yyyy-MM-dd') + ".post")
          },
          method: 'post',
          url: `${BASE_URL}${path}`,
          data: obj
      })
          .then((res) => res.data)
          .then((json) => (json.statusCode >= 301 ? reject(json) : resolve(json)))
          .catch((error) => {
              reject(error)
              console.log(error)
          })
  })
}