import { useState, useEffect } from "react";

import api from "../api/api";

import { sha256 } from 'js-sha256';
import { format } from "date-fns";

const useFetch = () => {
  const [data, setData] = useState({
    slug: "",
    results: [],
  });

  useEffect(() => {
    if (data.slug !== "") {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            //console.log("USEFETCH : " + process.env.REACT_APP_API_KEY + "." + format(new Date(),'yyyy-MM-dd') + ".get");
            const res = await api.get(`/lotto/check-stats?q=${data.slug}`, {
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'x-api-key' : sha256(process.env.REACT_APP_API_KEY + "." + format(new Date(),'yyyy-MM-dd') + ".get")
                                  }
            });
            //const res = await api.get(`/${data.slug}`);
            setData({ ...data, results: res.data });
          } catch (err) {
            console.error(err);
          }
        };
        fetch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [data.slug]);

  return { data, setData };
};

export default useFetch;