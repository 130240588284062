import React from 'react';
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {Carousel} from "react-bootstrap";
//import {Carousel} from "react-responsive-carousel";
import PlaceholderImage from 'assets/logo_full.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

import "css/carousel.css";

import { POST } from "api/api.js";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getAdsProduct } from "data/adsProduct.js";
   

const DirectAdsCarousel = () => {

    const adsProductArray = getAdsProduct();

    const handleAnchorClick = async (event) => {

        try {
            let lottoActionResponse = await POST('/lotto/action', {ctaType : event.target.id, ctaDetail : 'lead to ' + event.target.id});
            console.log("call success");
        } catch (error) {
            console.error('/lotto/action --> error: ', error)
        }
        
    };

    return (
        <div> 
            
            <Carousel
                autoPlay={true}
                interval={1000}
                variant="dark"
                touch={true}
                indicators={false}
            >
                {adsProductArray?.map((a, i) => (

                    <Carousel.Item key={i}>

                        <a  
                            href={a.affiliateUrl}
                            target="_blank"
                            rel="noreferrer nofollow"
                            style={{ textDecoration: "none"}}
                            onClick={handleAnchorClick}
                        >
                            <div id={a.id} className="blinktext" style={{fontSize:"1.25rem"}}>
                                ช้อปของถูกกับลาซาด้า <br/> คลิกเลย!!! 
                            </div>
            
                            <LazyLoadImage 
                                id={a.id}
                                rel="noreferrer dns-prefetch"
                                src={a.adsImageUrl}
                                className="d-block"
                                style={{maxWidth: '150px', maxHeight: '150px', margin: 'auto'}}
                                alt={a.adsCaption}
                                title={a.adsCaption}
                                placeholdersrc={PlaceholderImage}
                                effect="blur"
                            />
                            <div id={a.id} style={{ fontWeight: 'bold', fontSize: '0.75rem', color: 'black' }}>
                                {a.adsCaption}
                            </div>
                        </a>

                        <Carousel.Caption>
                        </Carousel.Caption>
                        
                    </Carousel.Item>
                ))
                }
            </Carousel>
            <hr/>
        </div>
    );
};

export default DirectAdsCarousel;