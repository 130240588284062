import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import {directHighlight} from 'components/lotto/utility/Utility.js';

function checkForDirectHighlight(r, textToHighlight){

  if (r.typeDescription.includes('สองตัว') && r.winRate >= 1.3 
  || r.typeDescription.includes('2ตัว') && r.winRate >= 1.3 
  || r.typeDescription.includes('สามตัว') && r.winRate >= 0.13 
  || r.typeDescription.includes('3ตัว') && r.winRate >= 0.13){
    return directHighlight(textToHighlight);
  } 
  else {
    return textToHighlight;
  }
}

function renderResultArrayAsTr(text, resultArray, backgroundColor) {
    //console.log("text = " + text)
    //console.log("resultArray = " + JSON.stringify(resultArray))

    return  (resultArray.map((r, i) => (
              <Tr bgcolor={backgroundColor} key={i}>
                <Td>   
                  <h4 style={{fontSize:"1rem"}}>{checkForDirectHighlight(r, text)}   </h4>          
                </Td>
                <Td> 
                  <h4 style={{fontSize:"1rem"}}>{checkForDirectHighlight(r, r.typeDescription)}    </h4>             
                </Td>
                {/*
                <Td> {r.times} </Td>
                <Td> {r.totalTimes}   </Td>
                */}
                <Td> 
                  <h4 style={{fontSize:"1rem"}}>{checkForDirectHighlight(r, r.winRate)}% </h4>
                </Td>
              </Tr>
          ))
    )
        
}

export default function LottoResultItems({ results }) {

  //console.log("LottoResultItems:results = " + JSON.stringify(results))
  
  const yearsFromBegin = (new Date().getFullYear() -  1990);

  return (

    <Table>
        <Thead>
          <Tr bgcolor="#f2f2f2">
            <Th><h3 style={{fontSize:"1rem"}}><b> ช่วงเวลา </b></h3></Th>
            <Th><h3 style={{fontSize:"1rem"}}><b> รางวัล</b></h3></Th>
            {/*
            <Th><b> ถูก (งวด)</b> </Th>
            <Th><b> จาก (งวด)</b> </Th>
            */}
            <Th><h3 style={{fontSize:"1rem"}}><b> % ถูกรางวัล</b></h3></Th>
          </Tr>
        </Thead>
        <Tbody>
          {renderResultArrayAsTr("ย้อนหลัง " + yearsFromBegin + "ปี", results.dataAll, "#ffffbf")}
          {renderResultArrayAsTr("ย้อนหลัง 1ปี", results.data1Year, "#d9ef8b")}
          {renderResultArrayAsTr("ย้อนหลัง 3ปี", results.data3Years, "#D6EEEE")}
          {renderResultArrayAsTr("เดือนนี้ย้อนไป " + yearsFromBegin + "ปี", results.dataMonthMatch, "#fee08b")}
          {renderResultArrayAsTr("รอบนี้ย้อนไป " + yearsFromBegin + "ปี", results.dataRoundMatch, "#ecc9ee")}
        </Tbody>
      </Table>
  );
}