import React from 'react'
import * as rdd from 'react-device-detect';
import PaoTung from 'assets/paotung.png';
import { POST } from 'api/api';
import Countdown from "react-countdown";
import PlaceholderImage from 'assets/logo_full.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

const OtherApps = ({nextLottoTimestamp}) => {

    //console.log("BEF#nextLottoTimestamp = " + nextLottoTimestamp);
    if (nextLottoTimestamp){
      nextLottoTimestamp = nextLottoTimestamp?.substring(0, nextLottoTimestamp?.length - 2).replace(" ","T");
    }
    //console.log("AFT#nextLottoTimestamp = " + nextLottoTimestamp);
    
    const handleAnchorClick = async (event) => {

        try {
            //console.log(event.target.id);
            window.open("https://c.lazada.co.th/t/c.bA74Ec?sub_aff_id=lazada_bonus_deeplink");
            const lottoActionResponse = await POST('/lotto/action', {ctaType : event.target.id, ctaDetail : 'lead to ' + event.target.id});
            
            //console.log("call success");
        } catch (error) {
            console.error('/lotto/action --> error: ', error)
        }
        
    };

    const Completionist = () => <span></span>;

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <Completionist />;
        } else {
          // Render a countdown
          if (days > 0){
            return <span className="blinktext">งวดถัดไปจะเริ่มในอีก <br/> {days} วัน {hours} ชั่วโมง {minutes} นาที {seconds} วินาที <br/><br/></span>;
          }
          else if (hours > 0){
            return <span className="blinktext">งวดถัดไปจะเริ่มในอีก <br/> {hours} ชั่วโมง {minutes} นาที {seconds} วินาที <br/><br/></span>;
          }
          else if (minutes > 0){
            return <span className="blinktext">งวดถัดไปจะเริ่มในอีก <br/> {minutes} นาที {seconds} วินาที <br/><br/></span>;
          }
          else {
            return <span className="blinktext">งวดถัดไปจะเริ่มในอีก <br/> {seconds} วินาที <br/><br/></span>;
          }
        }
    };
      
    return (

        <div>
            <h2 style={{fontSize:"1.25rem"}}>
              {
                  nextLottoTimestamp &&
                  <Countdown date={nextLottoTimestamp} renderer={renderer} />
              }
              
              ซื้อเลขเด็ดของคุณได้ที่ 
            </h2>
            
              <a  
                  href={rdd.isIOS 
                          ? "https://apps.apple.com/th/app/%E0%B9%80%E0%B8%9B-%E0%B8%B2%E0%B8%95-%E0%B8%87/id1324902182?l=th" 
                          : "https://play.google.com/store/apps/details?id=com.ktb.customer.qr&hl=th&gl=US"
                      } 
                  rel="noreferrer dns-prefetch"
                  onClick={handleAnchorClick}
              >
                  <LazyLoadImage 
                      id="paotung" 
                      title="Hotto x Paotung Mobile App"
                      alt="paotung-app" 
                      src={PaoTung} 
                      loading="lazy"
                      className="paotung"
                      placeholdersrc={PlaceholderImage}
                      effect="blur"
                  />
              </a>
            
            <hr/>

        </div>
        
   );
};

export default OtherApps 