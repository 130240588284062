import React from "react";

import 'css/style.css';

import {ResultCollapsible} from 'components/lotto/utility/ResultCollapsible'


export default function LottoResults({ data, isShowAtRender}) {

  //console.log("LottoResults:data = " + JSON.stringify(data))
  //console.log("LottoResults:results = " + JSON.stringify(results))

  let results = data.results;

  if (results?.data.length === 0 && results?.dreamList.length === 0){ 
        return (<div><font color="red" size="2rem"> ไม่พบข้อมูล <br/><br/></font></div>);
  }
  else if (results?.data.length === 0  && results?.dreamList.length > 0){
      return (
          
        <div>
              <ResultCollapsible id="9" topic={"ความฝันที่เกี่ยวข้องกับ" + data.slug} table={results?.dreamList} slug={data.slug} isShowAtRender={isShowAtRender}/>
        </div>
      );
  }
  else{
        
        return (
        
        <div>
              
              <ResultCollapsible id="2" topic={"เลข " + data.slug + " : ประวัติการถูกรางวัล"} table={results?.keywordPrizeList} slug={data.slug} isShowAtRender={isShowAtRender}/>

              <ResultCollapsible id="9" topic={"เลข " + data.slug + " : ความฝันที่เกี่ยวข้อง"} table={results?.dreamList} slug={data.slug} isShowAtRender={isShowAtRender}/>

              <ResultCollapsible id="1" topic={"เลข " + data.slug + " : ถูกบ่อยแค่ไหน?"} table={results} slug={data.slug} isShowAtRender={isShowAtRender}/>

        
        </div>
      );
  }
}