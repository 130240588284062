import React, { Component  } from 'react'

import QRLINEOA from 'assets/QR_140ffoit.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from 'assets/logo_full.png';

class ContactUs extends Component {

    render () {
        const Mailto = ({ email, subject, body, children }) => {
            return (
              <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
            );
        };

        return(
            <div>
                <h2 style={{fontSize:"1.5rem"}}> ติดต่อเรา </h2>
                <h3 style={{fontSize:"1rem"}}>
                    EMAIL:&nbsp;
                    <Mailto email="admin@hotto.app" subject="ติดต่อเจ้าหน้าที่ hotto.app" body="เขียนเรื่องที่จะติดต่อเจ้าหน้าที่ hotto.app">
                        admin@hotto.app
                    </Mailto>
                    &nbsp;| LINE: <a href="https://line.me/R/ti/p/%40140ffoit">@140ffoit</a> 
                </h3>
                <LazyLoadImage 
                    src={QRLINEOA}
                    alt='qr-line-oa' 
                    title='QR code LINEOA Hotto.app' 
                    style={{height:"120px"}}
                    placeholdersrc={PlaceholderImage}
                    effect="blur"
                />
                <hr/>
            </div>
            )
        }
}

export default ContactUs 