import React from 'react';
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
//import {Carousel} from "react-responsive-carousel";

import {getLottoThDate, setQueryHyperlink} from 'components/lotto/utility/Utility.js';


const LottoPrize = ({prizeDataArray, slug}) => {

    //console.log("prizeDataArray = " + JSON.stringify(prizeDataArray))
    let latestPrize = prizeDataArray ? prizeDataArray[0] : null;
    //console.log("latestPrize = " + JSON.stringify(latestPrize));
    return  (   latestPrize && 
                <div>
                    <font color="black">
                        <h3>
                            {getLottoThDate(latestPrize?.lottoDate)} <br/> 
                            <b>{latestPrize?.finalPrize.substring(0,latestPrize?.finalPrize.length - 2)}{setQueryHyperlink(latestPrize?.finalPrize.substring(latestPrize?.finalPrize.length - 2),slug)}</b> <br/>
                            <b>{setQueryHyperlink(latestPrize?.down2Prize, slug)}</b>
                        </h3>
                    </font>
                {
                prizeDataArray.map((r, i) => (
                <div key={i}>
                    {
                        i > 0 ? <div> 
                                    <h3 style={{fontSize:"1rem"}}>
                                        {  getLottoThDate(r.lottoDate) 
                                        + " | " 
                                        + r.finalPrize.substring(0,r.finalPrize.length - 2) 
                                        }
                                        {setQueryHyperlink(r.finalPrize.substring(r.finalPrize.length - 2), slug)}
                                        &nbsp;|&nbsp;
                                        {setQueryHyperlink(r.down2Prize, slug)}
                                    </h3>
                                </div>
                              : ""
                    }
                </div>
                ))
                }
                <br/>
            </div>
    );
    }

export default LottoPrize;