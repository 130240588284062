import React from 'react';
import { Routes, Route, Navigate, useParams} from 'react-router-dom';

import 'App.css';


import NoPage from "pages/nopage";
import Main from "components/Main";
import Search from "components/lotto/Search";

import FbLineLogin from "pages/line/fb-line-login";
import FbLineLoginOpenSesame from "pages/line/fb-line-login-opensesame";

import LineLogin from "pages/line/line-login";
import LineOAuthCallback from "pages/line/line-oauth-callback";

import ArticleDetail from "components/lotto/ArticleDetail";

class App extends React.Component {
  
  render() {
    console.log("App.js");
 
    function NavigateWithParams({ to, ...rest }) {
      const params = useParams();
      let toValue;
      if (typeof to === 'function') {
        toValue = to(params);
      } else {
        toValue = to;
      }
      return <Navigate to={toValue} {...rest} />
    }
    
    return (
      <Routes>
      
        <Route path="fb/line/login" element={<FbLineLogin />} />

        <Route path="fb/line/opensesame" element={<FbLineLoginOpenSesame />} />

        <Route path="line/login" element={<LineLogin />} />

        <Route path="line/oauthcallback" element={<LineOAuthCallback />} />

        <Route exact path={"/search/:keyword"}  element={<Main />} />
        
        <Route path="/article/:articleSlug" 
              element={
                        <NavigateWithParams 
                          to={params => `/article?slug=${params.articleSlug}`} 
                          replace
                        />
                      }
        />

        <Route path="/article/"  element={<ArticleDetail />} />
        

        <Route path="/home" element={<Main />}/>

        <Route path="/" element={<Main />} />
      
        <Route path="*" element ={<NoPage />} />

    </Routes>
    );
  }
}
export default App;