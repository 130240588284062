import React from "react";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

export function highlight(text, highlight) {
  
  if (!highlight){
      return text;
  }

  if (highlight === null || highlight?.length === 0 || highlight === " " || highlight === ","){
    return text;
  }
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <span>{parts.map((part,i) => part.toLowerCase() === highlight.toLowerCase() ? <mark key={i}><b><font color='green'>{part}</font></b></mark> : part)}</span>;
}

export function directHighlight(text) {
  return <span><mark><b><font color='green'>{text}</font></b></mark></span>;
}

export function setQueryHyperlink(text, slug) {

      var numbers = text.split(",");
      var countNum = 1;

      const handleAnchorClick = async (event) => {

            //console.log(event.target.id);
            window.open("https://c.lazada.co.th/t/c.bA74Ec?sub_aff_id=lazada_bonus_deeplink");
            //console.log("call success");
      
      };

      return (
            <>
                  {
                  numbers.map((n,i) => 
                        (!isNaN(+n.trim())
                        ?     
                              <span key={i}>
                                    <a  
                                    href={"/search/" + n.trim()}
                                    rel="noreferrer"
                                    onClick={handleAnchorClick}
                                    > 
                                    {slug && slug.length === n.trim().length ? highlight(n.trim(),slug) : n.trim()}
                                    </a>
                                    &nbsp;{countNum++ % 10 === 0 ? <br/> : ""}
                              </span>
                        : (<span key={i}> {i == 0 ? "" : <br/> }{n.trim()}&nbsp;</span>) 
                        )
                  )
                  }
            </>
      );

}

export function hintTypeDescription(hintType){
  if (hintType === "CALENDAR_CHINESE01"){
      return "เลขปฎิทินจีน";
  } 
  else if (hintType === "CALENDAR_KUMCHANOD"){
      return "เลขคำชะโนด";
  }
  return "N/A";
}

export function getLottoThDate(date){

      if (date){
            date = date?.substring(0, date?.length - 2).replace(" ","T") + "Z";
      }

      return "งวด " + (new Date(date)).toLocaleDateString('th-TH',{year: 'numeric', month: 'short', day: '2-digit'});
}

export function getThDate(date){

      if (date){
            date = date?.substring(0, date?.length - 2).replace(" ","T") + "Z";
      }

      return (new Date(date)).toLocaleDateString('th-TH',{year: 'numeric', month: 'short', day: '2-digit'});
}

export function prepareTxtTitle(mode, keyword){
      if (mode === "number") return "Hotto | เช็คสถิติเลข " + keyword;
      if (mode === "dream") return  "Hotto | ทำนายเลขเด็ดจากฝัน ฝันเห็น" + keyword + " ฝันถึง" + keyword;
      return "Hotto | ครบเครื่องเรื่องหวย เลขเด็ดงวดนี้ ทำนายฝัน";
}

export function prepareTxtDescription(mode, keyword){
      if (mode === "number") return "เช็คสถิติเลขเด็ด " + keyword + " ถูกรางวัลบ่อยแค่ไหน , สถิติเลข " + keyword + " ย้อนหลัง 32 ปี, ความฝันที่เกี่ยวข้องกับเลข " + keyword + " / เลขเด็ดคำชะโนด ปฎิทินจีน เช็คสถิติเลขเด็ดย้อนหลัง 32 ปี";
      if (mode === "dream") return  "เลขเด็ดที่เกี่ยวข้องกับความฝัน ฝันเห็น" + keyword + " ฝันถึง" + keyword + " / หาเลขเด็ดจากฝันยอดฮิต เช็คประวัติการถูกรางวัลของเลขเด็ดที่คุณมี";
      return "หาเลขเด็ดจากเลขคำชะโนด ปฎิทินจีน หาเลขเด็ดจากฝันยอดฮิต หาเลขเด็ดจากฝันของคุณ เช็คสถิติเลขเด็ดย้อนหลัง 32 ปี เช็คประวัติการถูกรางวัลของเลขเด็ดที่คุณมี";
}

export function prepareTxtKeywords(mode, keyword){
      
      var klist = "เลขเด็ด 17/1/65,เลขเด็ด 1/2/65,เลขเด็ด 16/2/65,เลขเด็ด 1/3/65,เลขเด็ด 16/3/65,เลขเด็ด 1/4/65,เลขเด็ด 16/4/65,เลขเด็ด 1/5/65,เลขเด็ด 16/5/65,เลขเด็ด 1/6/65,เลขเด็ด 16/6/65,เลขเด็ด 1/7/65,เลขเด็ด 16/7/65,เลขเด็ด 1/8/65,เลขเด็ด 16/8/65,เลขเด็ด 1/9/65,เลขเด็ด 16/9/65,เลขเด็ด 1/10/65,เลขเด็ด 16/10/65,เลขเด็ด 1/11/65,เลขเด็ด 16/12/65"
                  + ",เลขเด็ด 17/1/66,เลขเด็ด 1/2/66,เลขเด็ด 16/2/66,เลขเด็ด 1/3/66,เลขเด็ด 16/3/66,เลขเด็ด 1/4/66,เลขเด็ด 16/4/66,เลขเด็ด 1/5/66,เลขเด็ด 16/5/66,เลขเด็ด 1/6/66,เลขเด็ด 16/6/66,เลขเด็ด 1/7/66,เลขเด็ด 16/7/66,เลขเด็ด 1/8/66,เลขเด็ด 16/8/66,เลขเด็ด 1/9/66,เลขเด็ด 16/9/66,เลขเด็ด 1/10/66,เลขเด็ด 16/10/66,เลขเด็ด 1/11/66,เลขเด็ด 16/12/66"
                  + ",เลขเด็ด , ฝันว่า , ฝันถึง , ฝันเห็น , ฝันเห็นคนตาย , ฝันเห็นพระ , ฝันเห็นปลา , ฝันเห็นเด็ก , ฝันเห็นเลือด , ฝันเห็นช้าง , ฝันเห็นทอง , ฝันเห็นหนอน , ฝันว่าตัดผม , ฝันเห็นเต่า"
                  + ",เช็คหวย , ตรวจหวย , หวย , ถูกหวย , หวยเด็ด , ทำนายฝัน , เลขเด็ดงวดนี้ , เลขเด็ดงวดล่าสุด"
                  + ",ดาวโจนส์ นิเคอิ หุ้นไทย หวยรัฐ ฮานอย เลขเด็ด,ฝันเห็นพญาครุฑ เลขเด็ด,เลขเด็ด ไทยรัฐ เดลิ นิ ว ส์ บ้านเมือง,หวยเด็ด เลขเด็ด ซองดัง public,ฝันว่าจับอวัยวเพศผู้ชาย เลขเด็ด,เลขเด็ด หลุดกองสลาก,เลขเด็ด หวยรัฐบาล งวดนี้,เลขเด็ด ฝันว่า ตัว เอง ท้อง,ฝันเห็นพระ สงฆ์ 1 รูป เลขเด็ด,ฝันเห็นแฟนเก่า เลขเด็ด,ฝันเห็นอุจจาระ เลขเด็ด,เลขเด็ด เวียดนาม เน้นๆวันนี้,เลขเด็ด อาจารย์ดัง เข้าทุกงวด"
                  + ",ฝันเห็นยายที่เสียไปแล้ว เลขเด็ด,เลขเด็ด ที่สุดในโลก,หวยเด็ด เลขเด็ด หวยไทยรัฐ แม่จำเนียร,เลขเด็ด ไทยรัฐ เดลิ นิ ว ส์ บ้านเมือง,หวยเด็ด เลขเด็ด ซองดัง public,ฝันว่ามีประจําเดือนใส่ผ้าอนามัย เลขเด็ด,เลขเด็ด หลุดกองสลาก,ตําราทํานายฝัน 108 เลขเด็ด,ฝันเห็นท้าวเวสสุวรรณ เลขเด็ด,ฝันเห็นพระพิฆเนศ เลขเด็ด,ฝันเห็นงูหลายตัว เลขเด็ด"
                  + ",เลขเด็ด ประ จํา วันจันทร์,เลขเด็ด ประ จํา วันอังคาร,เลขเด็ด ประ จํา วันพุธ,เลขเด็ด ประ จํา วันพฤหัสบดี,เลขเด็ด ประ จํา วันศุกร์,เลขเด็ด ประ จํา วันเสาร์,เลขเด็ด ประ จํา วันอาทิตย์"
                  + ",เลขเด็ด ประ จํา เดือนมกราคม,เลขเด็ด ประ จํา เดือนกุมภาพันธ์,เลขเด็ด ประ จํา เดือนมีนาคม,เลขเด็ด ประ จํา เดือนเมษายน,เลขเด็ด ประ จํา เดือนพฤษภาคม,เลขเด็ด ประ จํา เดือนมิถุนายน"
                  + ",เลขเด็ด ประ จํา เดือนกรกฎาคม,เลขเด็ด ประ จํา เดือนสิงหาคม,เลขเด็ด ประ จํา เดือนกันยายน,เลขเด็ด ประ จํา เดือนตุลาคม,เลขเด็ด ประ จํา เดือนพฤศจิกายน,เลขเด็ด ประ จํา เดือนธันวาคม"
                  + ",ฝันเห็นงูตัวใหญ่ เลขเด็ด,เห็นตัวเงินตัวทองเข้าบ้าน เลขเด็ด,ฝันว่าคลอดลูก เลขเด็ด"
                  + ",ฝันถึงแฟนเก่า เลขเด็ด,ฝันเห็นคนที่เสียชีวิตไปแล้ว เลขเด็ด,ฝันเห็นเครื่องบิน เลขเด็ด,ฝันว่ารถยนต์หาย เลขเด็ด,ฝันว่าไฟไหม้ เลขเด็ด"
                  + ",ฝันว่าพ่อเสียชีวิต เลขเด็ด,ฝันว่าว่ายน้ํา เลขเด็ด,ฝันว่าน้ําท่วมถนน เลขเด็ด,ฝันว่าถูกตํารวจจับ เลขเด็ด,ฝันเห็นน้ำท่วม เลขเด็ด"
                  + ",เลขเด็ด อาจารย์ดัง เข้าทุกงวด,เลขเด็ด เข้าทุกงวด,รวม เลขเด็ด อาจารย์ ดัง, รวม เลขเด็ด อาจารย์ ดัง facebook"
                  + ",เลขเด็ด เวียดนาม เน้นๆวันนี้, เลขเด็ด อาจารย์ดัง เข้าทุกงวด, หวย เลขเด็ด vip facebook,กลุ่ม สาธารณะ เลขเด็ด facebook"
                  + ",ฝันเห็นพระ สงฆ์ 1 รูป เลขเด็ด, ฝันเห็นอุจจาระ เลขเด็ด, ฝันเห็นแฟนเก่า เลขเด็ด, กลุ่ม รวม เลขเด็ด ๆ หวยดัง public group facebook"
                  + ",หวยเด็ด เลขเด็ด ซองดัง public, เลขเด็ด หวยเด็ด lottonew facebook, หวยเด็ด เลขเด็ด หวยไทยรัฐ แม่จำเนียร"
                  + ",ฝันว่าได้จับปลาเยอะมาก เลขเด็ด, ฝันว่าโดนยิง แต่ไม่เป็นไร เลขเด็ด, ฝันว่าได้ใส่แหวนทอง เลขเด็ด, ฝันเห็นเด๋็กหญิง เลขเด็ด, เลขเด็ด ซองดัง v2 facebook, ฝันเห็นงูตัวใหญ่สีดํา 1 ตัว เลขเด็ด, "
                  + ",เลขเด็ด แม่นๆ งวดนี้ facebook ฟรี"
                  + ",เลขเด็ด เวียดนาม เน้นๆวันนี้, เลขเด็ด ไทยรัฐ เดลิ นิ ว ส์ บ้านเมือง, เลขเด็ด ที่สุดในโลก, ดาวโจนส์ นิเคอิ หุ้นไทย หวยรัฐ ฮานอย เลขเด็ด, ทํานายฝัน mthai เลขเด็ด, ฝันเห็นแฟนเก่า เลขเด็ด, ฝันเห็นอุจจาระ เลขเด็ด, ทํานายฝัน แก้ฝัน เลขเด็ด, ทํานายฝัน เลขเด็ด แม่นๆ ฟรี"
                  + ",หวยเด็ด เลขเด็ด ซองดัง public, ฝันว่ามีประจําเดือนใส่ผ้าอนามัย เลขเด็ด, กลุ่ม ร่วม เลขเด็ด ๆ หวยดัง public group facebook, ฝันว่าเหาขึ้นหัว เลขเด็ด, ฝันเห็นงูสีดํา เลขเด็ด, ฝันว่าจับอวัยวเพศผู้ชาย เลขเด็ด"
                  + ",หวย ลาว วัน นี้, ตา ว จ หวย, หวย ไทยรัฐ, ตรวจ ล้อ ต เต อ รี่, หวย, หวย หุ้น,หวย เด่น, ตรวจ หวย ลาว วัน นี้ ล่าสุด, ยี่ กี รวย, ruay หวย, หวย ลาว mthai, หวย ชัวร์ 100, หวย แพนด้า, เลข เด็ด งวด นี้ ไทยรัฐ, ตรวจ ลอตเตอรี่ รางวัล ที่ 1, รางวัล ที่ 1 งวด นี้, ตรวจ รางวัล สลากกินแบ่ง รัฐบาล, ตรวจ ผล สลากกินแบ่ง, ผล สลาก ล่าสุด, หวย ดัง, ตรวจ หวย งวด ล่าสุด, หวย เด็ด ๆ เข้า ทุก งวด, ตรวจ รางวัล ลอตเตอรี่, เลข ดัง งวด นี้, หวย ย้อน หลัง 10 ปี, sanook หวย, เลข เด็ด งวด นี้ ที่สุด ใน โลก, หวย เด็ด, หวย รัฐบาล ย้อน หลัง, เลข เด็ด อาจารย์ ดัง เข้า ทุก งวด, เลข ออก วัน นี้, รวย หวย, ฟัน ธง หวย หุ้น, ตรวจ ผล สลาก, หวย ล่าสุด, หวย หุ้น แม่น ๆ, หวย ย้อน หลัง, เลข เด็ด, เลข เด็ด งวด นี้, ตรวจ รางวัล, หวย วัน นี้, หวย ออก, hihuay, ruay"
                  + ",ปฏิทิน เลข ฟัน ธง, เลข เด็ด วัน อาทิตย์, เลข เด็ด วัน จันทร์, เลข เด็ด วัน อังคาร, เลข เด็ด วัน พุธ, เลข เด็ด วัน พฤหัสบดี, เลข เด็ด วัน ศุกร์, เลข เด็ด วัน เสาร์"
                  + ",ตรวจ หวย 1 มกราคม 2565, ตรวจ หวย 16 มกราคม 2565, ตรวจ หวย 1 กุมภาพันธ์ 2565, ตรวจ หวย 16 กุมภาพันธ์ 2565, ตรวจ หวย 1 มีนาคม 2565, ตรวจ หวย 16 มีนาคม 2565, ตรวจ หวย 1 เมษายน 2565, ตรวจ หวย 16 เมษายน 2565, ตรวจ หวย 1 พฤษภาคม 2565, ตรวจ หวย 16 พฤษภาคม 2565, ตรวจ หวย 1 มิถุนายน 2565, ตรวจ หวย 16 มิถุนายน 2565, ตรวจ หวย 1 กรกฎาคม 2565, ตรวจ หวย 16 กรกฎาคม 2565, ตรวจ หวย 1 สิงหาคม 2565, ตรวจ หวย 16 สิงหาคม 2565, ตรวจ หวย 1 กันยายน 2565, ตรวจ หวย 16 กันยายน 2565, ตรวจ หวย 1 ตุลาคม 2565, ตรวจ หวย 16 ตุลาคม 2565, ตรวจ หวย 1 พฤศจิกายน 2565, ตรวจ หวย 16 พฤศจิกายน 2565, ตรวจ หวย 1 ธันวาคม 2565, ตรวจ หวย 16 ธันวาคม 2565"
                  + ",ตรวจ หวย 1 มกราคม 2566, ตรวจ หวย 16 มกราคม 2566, ตรวจ หวย 1 กุมภาพันธ์ 2566, ตรวจ หวย 16 กุมภาพันธ์ 2566, ตรวจ หวย 1 มีนาคม 2566, ตรวจ หวย 16 มีนาคม 2566, ตรวจ หวย 1 เมษายน 2566, ตรวจ หวย 16 เมษายน 2566, ตรวจ หวย 1 พฤษภาคม 2566, ตรวจ หวย 16 พฤษภาคม 2566, ตรวจ หวย 1 มิถุนายน 2566, ตรวจ หวย 16 มิถุนายน 2566, ตรวจ หวย 1 กรกฎาคม 2566, ตรวจ หวย 16 กรกฎาคม 2566, ตรวจ หวย 1 สิงหาคม 2566, ตรวจ หวย 16 สิงหาคม 2566, ตรวจ หวย 1 กันยายน 2566, ตรวจ หวย 16 กันยายน 2566, ตรวจ หวย 1 ตุลาคม 2566, ตรวจ หวย 16 ตุลาคม 2566, ตรวจ หวย 1 พฤศจิกายน 2566, ตรวจ หวย 16 พฤศจิกายน 2566, ตรวจ หวย 1 ธันวาคม 2566, ตรวจ หวย 16 ธันวาคม 2566"
                  ;
      if (mode === "number") klist = "เลขเด็ด, เลขเด็ด " + keyword + ", เช็คหวย , ตรวจหวย , หวย , ถูกหวย , หวยเด็ด , ทำนายฝัน , เลขเด็ดงวดนี้ , เลขเด็ดงวดล่าสุด" + "," + klist;
      if (mode === "dream") klist =  "เลขเด็ด , ฝันว่า" + keyword + " , ฝันถึง" + keyword + " , ฝันเห็น" + keyword + " , เช็คหวย , ตรวจหวย , หวย , ถูกหวย , หวยเด็ด , ทำนายฝัน , เลขเด็ดงวดนี้ , เลขเด็ดงวดล่าสุด" + "," + klist;
      
      return klist;
}

export function prepareTxtCanonicalUrl(mode, keyword){
      /** 
      if (mode === "number" || mode === "dream") return "https://hotto.app/search/" + keyword;
      if (mode === "article") return "https://hotto.app/article/" + keyword;
      return "https://hotto.app";**/

      if (mode === "number" || mode === "dream") return window.location.href;
      if (mode === "article") return window.location.href;
      return window.location.href;
}
