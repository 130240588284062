import React, { Component } from 'react'
import * as rdd from 'react-device-detect';

export default class FbLineLogin extends Component {
    render() { 
        console.log("FbLineLogin");
    
        if (rdd.isMobile || rdd.isTablet){
            window.location.assign("line://app/2006327622-72EmENyl");
        }
        else{
            window.location.assign("https://liff.line.me/2006327622-72EmENyl");
        }
        return (<div>FB redirect to LINE...</div>)
        
    }
}
